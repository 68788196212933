import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import {
  Layout,
  Seo,
  Nav,
  VerticalSection,
  ValueProp,
  Cta,
} from "components/common"

import map from "lodash/map"

import { useContentfulSection, useMeta, device } from "utils"

const LinkinbioPage = ({ data: { linkinbio } }) => {
  const {
    linkinbio: { hatIcon, title, subtitle, valueProps, ctas },
  } = useContentfulSection(linkinbio.sections)

  const meta = useMeta(linkinbio)

  const renderGrid = () => {
    return (
      <Grid>
        {map(valueProps, (vp) => (
          <ValueProp
            key={vp.id}
            {...vp}
            orientation="horizontal"
            titleAs="p"
            isCard
          />
        ))}
      </Grid>
    )
  }

  const renderCta = () => <Cta ctas={ctas} />

  return (
    <Layout hideFooter>
      <Seo {...meta} />
      <Nav />

      <Wrap>
        <VerticalSection
          className="linkinbio"
          titleAs="h5"
          subtitleAs="pSmall"
          align="center"
          hatIcon={hatIcon}
          title={title}
          subtitle={subtitle}
          grid={renderGrid}
          cta={renderCta}
          ctaOrder="5"
          mobileCtaOrder="5"
        />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  --tg-max-width: 289px;

  .hatIconWrap {
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    img {
      width: 112px;
      height: 112px;
      border-radius: 50%;
      box-shadow: var(--card-shadow);
    }
  }

  .gridWrap {
    margin-top: var(--sp-24);
  }

  .ctaWrap {
    width: 100%;
    max-width: 327px;
    --cta-grid-gap: var(--sp-16);
    margin-top: var(--sp-16);

    div {
      display: grid;
      text-align: center;
      grid-template-columns: 1fr;

      button {
        display: flex;
        width: 100%;
      }
    }
  }
`

const Grid = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  grid-gap: var(--sp-8);

  @media ${device.tablet} {
    max-width: 327px;
  }

  li {
    --card-padding: var(--sp-8);
    --card-radius: var(--sp-8);
    border-radius: var(--sp-8);
    grid-gap: var(--sp-16);
    width: 100%;
    transition: 0.2s box-shadow ease-in-out;
    will-change: box-shadow;

    &:hover {
      box-shadow: var(--card-shadow-hover);
    }

    .vp_contentWrap {
      align-items: center;
      .image {
        max-width: var(--sp-64);
        border-radius: var(--sp-8);

        img {
          border-radius: var(--sp-8);
        }
      }

      .title {
        font-weight: 500;
      }
    }
  }
`

export const query = graphql`
  {
    linkinbio: contentfulPage(pageId: { eq: "linkinbio" }) {
      ...Page
    }
  }
`

export default LinkinbioPage
